import React from 'react'
import Layout from "../../components/App/Layout"
import SEO from '../../components/App/SEO';
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import FeatureFunctions from '../../components/AboutUs/FeatureFunctions'
import featureImg from '/static/img/security-features/security-center-SMIME.webp'
import {graphql} from 'gatsby'
const img = '/img/security-features/security-center-SMIME.webp'

const dataSMIME = {
  title: "SMIME: An end-to-end secure layer of email encryption",
  subtitle: <div>
 <p>Every day, over 300 Billion emails are sent around the world, and while many are from trusted senders, an ever growing number are from scammers and imposters.&nbsp;</p>

<p>At BlueMail, security and privacy are in our DNA and that&rsquo;s why in addition to encrypting the communication channel that you use to send and receive emails, we also support the integration of S/MIME, which can be used to both verify the sender of an email and encrypt your emails. This tool, along with the many other tools in the BlueMail security toolbox, helps defend against potential cyber threats and keeps your information secure on a daily basis.</p>

<h4>What is S/MIME?</h4>

<p>S/MIME creates a multi-layered approach to email security and privacy, by functioning both as a digital signature and also as an encryption protocol.&nbsp;</p>

<p><strong>Digital Signature:&nbsp; </strong>S/MIME certificates help you verify an email sender&#39;s identity, so you can be 100% sure that you are talking directly to the sender and there is no &ldquo;man in the middle&rdquo; listening in or manipulating the conversation.</p>

<p><strong>Encryption:</strong> S/MIME&rsquo;s&nbsp; encryption ensures that no one has tampered with or accessed the data or attachments in your email.</p>

<h3>How does S/MIME actually work?</h3>

<p>S/MIME uses public and private keys when sending and receiving email (keys can be thought of as unique passwords).</p>

<p><strong>When sending an email: </strong>&nbsp;The message is signed by the <em>sender&rsquo;s </em>private key (digital signature) and encrypted using the <em>recipient&rsquo;s </em>public key</p>

<p><strong>When receiving an email: </strong>The recipient can verify who the sender is (by looking at the signed digital signature) and can decrypt the message using <em>their own</em> private key.&nbsp;</p>

<p>This flow provides end-to-end assurances for both the sender and receiver, and puts everyone at ease knowing that the integrity of the conversation is fully protected.&nbsp;</p>

<h3>Should you use it and how can it benefit your business?</h3>

<p>While S/MIME in the past was more commonly used by tech geeks and for confidential government communication, today, more and more companies are choosing S/MIME to add an extra layer of security in their communication.</p>

<p>If your business is handling email data such as: Credit card info, Personal/Customer data, IP, or any other confidential data, it is important that you look into securing this data with S/MIME encryption.&nbsp;</p>

<h4>A few indirect benefits of S/MIME include:</h4>

<p><strong>Improving business reputation </strong>- Having secure email communication with your clients means that these emails bearing your company&rsquo;s name, can&rsquo;t contain viruses or be tampered with. This establishes a better trust between your business and your clients.&nbsp;</p>

<p><strong>Full Compliance with privacy regulations </strong>- If your business passes &ldquo;personal data&rdquo; through its communication channels, you must adhere to the local regulations regarding how this data is protected. Using end to end encryption such as S/MIME, keeps you in compliance and helps you avoid fines.</p>

<p><strong>Identity theft prevention </strong>- Even if a hacker gets access to your emails via a man in the middle attack or otherwise, they will not be able to access the data within. This reduces the chance for confidential data being leaked along with your credentials. S/MIME helps you run your business in a productive manner and minimizes your risk.</p>

<h3>Does my account support S/MIME?</h3>

<p>S/MIME is supported for all Exchange accounts and is usually configured on the domain level. It does need to be enabled first on your Exchange server, so speak to your sys admin about it or take a look here: <a href="https://docs.microsoft.com/en-us/exchange/security-and-compliance/smime-exo/configure-smime-exo" title="https://docs.microsoft.com/en-us/exchange/security-and-compliance/smime-exo/configure-smime-exo">Configure S/MIME in Exchange Online | Microsoft Docs</a></p>

<h3>How to integrate S/MIME on BlueMail?</h3>

<p>You can find the &ldquo;Certificates&rdquo; tab right within BlueMail&rsquo;s settings and it takes just a few steps to set it up. </p> 

<h3>In Summary</h3>

<p>We at BlueMail are committed to protecting you and your data and our support for S/MIME is a natural step in that direction. If you haven&rsquo;t yet thought about fully securing your emails and protecting your confidential data, there&rsquo;s no better time like the present to add a powerful security tool like S/MIME to your arsenal and keep your communication flowing, worry free.</p>

</div>,
  img: featureImg,
  class: ''
}

const SMIME = ({data}) => {
    return (
        <Layout env={data.site.siteMetadata.env}>
            <SEO 
                postTitle='OAuth2 Security Protocol | BlueMail App' 
                postDescription='When adding your account, BlueMail uses highly private and secure authentication protocols and does not have access to your account password at any time.'
                postImage="/img/OG/og_image-security-center-SMIME.png"
                postURL='security/SMIME'
                postSEO
            />
            <Navbar />
            <FeatureFunctions data={dataSMIME} />
            <Footer />
        </Layout>
    )
}

export default SMIME

export const query = graphql`
query SMIMEQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`
